import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';
import DetailedTutorials from '../seo_elements/DetailedTutorials';
import StatsSection from '../seo_elements/StatsSection';
import HeroFeature from '../seo_elements/HeroFeature';
import BuilderOptions from '../seo_elements/BuilderOptions';

export class DiscordBotCommandBuilder extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const testimonials = [
            {
                title: "Simple Command Builder",
                subtitle: "Create a custom Discord bot with no coding required",
                quote: "Nothing comes close to BotGhost's simplicity and functionality.",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/3e1e41d58ed87d324887c17e3a9affea.jpg",
                name: "Proquer",
                role: "Bot Owner"
            },
            {
                quote: "BotGhost is very easy to use and very high quality services!",
                title: "Easy to use",
                subtitle: 'Easily create your own Discord bot commands',
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/pepe.jpg",
                name: "Dixon",
                role: "Server Admin"
            },
            {
                quote: "BotGhost's modules such as the Command Builder are very simple for use and easy to learn.",
                title: "Easy to use",
                subtitle: "Intuitive drag and drop interface",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/IMG_7277.png",
                name: "Mohammed",
                role: "Bot Designer"
            },
            {
                quote: "BotGhost is an amazing way to make your discord bots for free without any knowledge of coding.",
                title: "Discord Commands without coding",
                subtitle: "Create your own Discord bot commands",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/0.png",
                name: "Real_Trex24",
                role: "Discord Bot Maker"
            },
            // ... more testimonials
        ];
        const mainFeatures = [
            {
                title: "Drag and Drop Bot Maker",
                description: "Create your Discord bot with our intuitive drag-and-drop interface. Design custom commands, events, and automations without writing a single line of code. Perfect for beginners and experts alike.",
                bulletPoints: [
                    "Visual command builder",
                    "No coding knowledge needed"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
            },
            {
                title: "Advanced Command Builder",
                description: "Build complex commands with our powerful visual builder. Create interactive menus, buttons, modals, and more using our drag-and-drop interface. Perfect for creating engaging bot interactions.",
                bulletPoints: [
                    "Interactive components",
                    "If/Else Statements",
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CommandBuilderBG.png"
            },
            {
                title: "Pre-built Modules",
                description: "Choose from our extensive library of pre-built modules to add instant functionality to your bot. From welcome messages to moderation tools, we've got everything you need to enhance your server.",
                bulletPoints: [
                    "One-click module activation",
                    "Fully customizable"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Variable System",
                description: "Create dynamic responses with our powerful variable system. Store and retrieve data, create counters, track user stats, and more - all without touching any code.",
                bulletPoints: [
                    "Dynamic data storage",
                    "Custom variables"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/VariablesBG.png"
            },
            {
                title: "Advanced Message Builder",
                description: "Create beautiful Discord embeds with our visual embed designer. Customize colors, add fields, images, and more. Preview your embeds in real-time as you build them.",
                bulletPoints: [
                    "Visual embed builder",
                    "Real-time preview"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/EmbedBG.png"
            },
            {
                title: "Easy Bot Management",
                description: "Manage your Discord bot from our user-friendly dashboard. Monitor performance, update settings, and make changes to your bot in real-time - all from one central location.",
                bulletPoints: [
                    "Intuitive dashboard",
                    "Real-time configuration"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Conditional Logic",
                description: "Add smart decision-making to your bot with our visual logic builder. Create if-then conditions, loops, and complex workflows without any programming knowledge.",
                bulletPoints: [
                    "Visual logic editor",
                    "Complex automations"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/LogicBG.png"
            },
            {
                title: "Event System Builder",
                description: "Create automated responses to server events using our visual event builder. Set up welcome messages, member tracking, role assignments, and more without any coding.",
                bulletPoints: [
                    "Drag and drop event builder",
                    "Automated responses"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ScalableInfastructureBG.png"
            },
            {
                title: "Marketplace",
                description: "Browse our extensive marketplace filled with pre-made commands and events. Find and activate the perfect features for your server with just one click, or share your own creations with the community.",
                bulletPoints: [
                    "1000+ commands and events",
                    "One click activation"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/DatabaseBG.png"
            },
            {
                title: "Active Developer Badge",
                description: "Get the exclusive Discord Active Developer Badge in under 5 minutes. No coding required - just enable our module and run a command.",
                bulletPoints: [
                    "One-click setup",
                    "24hour wait time"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active_dev.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Visual Command Builder",
                description: "Build powerful Discord commands with our intuitive drag-and-drop interface. Design complex command flows by connecting blocks - no coding required.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
            },
            {
                title: "Interactive Components",
                description: "Add buttons, dropdown menus, and modals to your commands. Create engaging interactions that make your bot feel more dynamic and responsive.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Conditional Logic",
                description: "Create smart commands that respond differently based on conditions. Use if/else statements, permissions checks, and role requirements to build complex command flows.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ConditionalLogic.png"
            },
            {
                title: "Command Templates",
                description: "Save time with our library of pre-built command templates. Find and customize templates for common use cases, or save your own commands as templates.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CommandTemplate.png"
            },
            {
                title: "Error Handling",
                description: "Build robust commands with built-in error handling. Add custom error messages and fallback actions to handle edge cases gracefully.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CommandError.png"
            },
            {
                title: "2500 Command Limit",
                description: "Create up to 2500 commands with sub commands. Perfect for creating any bot.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/2500limit.webp"
            }
        ];




        const faqQuestions = [
            {
                question: "How do I create Discord bot commands?",
                answer: "Creating Discord bot commands with BotGhost is easy! Simply use our visual command builder to drag and drop actions, add responses, and connect them together. No coding required - just design your command flow and we'll handle the technical details."
            },
            {
                question: "How do I use bot commands in Discord?",
                answer: "To use bot commands in Discord, simply type '/' in any channel where the bot has permissions. You'll see a list of available commands. Select the command you want to use, fill in any required options, and press enter. The bot will then execute the command based on how it was configured."
            },
            {
                question: "How do I add commands to my Discord bot?",
                answer: "With BotGhost, adding commands is simple: 1) Go to your bot's dashboard 2) Click 'Create Command' 3) Use our visual builder to design your command 4) Set the command name and description 5) Click Save. Your new command will be available in Discord immediately!"
            },
            {
                question: "Why can't I use bot commands on Discord?",
                answer: "If you can't use bot commands, check: 1) The bot is online and in your server 2) You have the correct permissions 3) The bot has permissions in that channel 4) You're using the correct command syntax (usually starting with '/'). If issues persist, try reinviting the bot with administrator permissions."
            },
            {
                question: "How do I make custom commands for my Discord bot?",
                answer: "To create custom commands: 1) Log into your BotGhost dashboard 2) Use our drag-and-drop builder to design your command flow 3) Add responses, buttons, or menus 4) Set up conditions and variables if needed 5) Test your command in real-time 6) Save and deploy. Your custom command will be ready to use instantly!"
            },
            {
                question: "How do I check bot commands in Discord?",
                answer: "To check available bot commands: 1) Type '/' in any channel to see all commands 2) Click on a command to see its description and options 3) Use '/help' if available for detailed command information. You can also visit your bot's dashboard to see and manage all commands."
            },
            {
                question: "How do I change Discord bot commands?",
                answer: "To modify existing commands: 1) Go to your BotGhost dashboard 2) Find the command you want to edit 3) Click 'Edit' to open the visual builder 4) Make your changes to the command flow 5) Save your changes. Updates will be applied automatically to your bot."
            }
        ];

        return (
            <div>
                <Helmet>
                    <title>Discord Bot Command Builder | BotGhost</title>
                    <meta name="description" content="Create your own Discord bot without coding using our easy-to-use Discord bot command builder. Build custom commands, automate your server, and launch your bot in minutes." />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            Drag and drop <span className="text-[#f45142]">Discord Bot Command Builder</span>
                        </>
                    }
                    description="Build powerful Discord bot commands using our intuitive drag and drop builder. Craft custom interactions, set up server automation, and deploy your bot quickly - zero coding experience needed."
                    cta="Create a Command"
                    ctaSecondary="Command Builder"
                    ctaSecondaryLink="#demo"
                    stats={true}
                />

                <SEOBuilderDemo />

                <BuilderOptions
                    title="Everything you need to build powerful commands"
                    description="Create complex Discord bot commands with our drag and drop builder. Choose from a wide range of actions and conditions to automate your server."
                />

                {/* <DetailedTutorials
                    title={<>How to make a <span className="text-[#f45142]">Discord bot</span> on BotGhost</>}
                    subtitle="Follow these simple steps to create your own Discord bot using our discord bot maker."
                    steps={tutorialSteps}
                    ctaText="Create your bot"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}


                <HeroFeature
                    title={<>Design your own <span className="text-white">Discord bot commands</span></>}
                    description="Create complex Discord bot commands with our visual builder. Choose from a wide range of actions and conditions to automate your server."
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
                    bulletPoints={[
                        "Drag-and-drop command builder",
                        "Add buttons and menus",
                        "Design your own custom responses",
                        "Create up to 2500 commands"
                    ]}
                    ctaText="Start building commands"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Create your own Discord bot with our intuitive discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - all without writing a single line of code."

                    features={mainFeatures}
                /> */}

                {/* <Testimonial
                    quote="Simple & Customizable; A dream to bot makers who have coding knowledge but are short on time."
                    author="Fluster"
                    role="Discord Bot Maker"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/e443cd1a18680d56fd27f2d286c389e7.png"
                /> */}


                <StatsSection
                    title={<>The most trusted <span className="text-[#f45142]">Discord bot maker</span> platform</>}
                    description="Join millions of users who've created their perfect Discord bot with our powerful bot maker"
                    stats={[
                        {
                            number: "1.5M+",
                            label: "Users",
                            description: "Active users trust our Discord bot maker"
                        },
                        {
                            number: "10M+",
                            label: "Commands Created",
                            description: "Custom commands built by our community"
                        },
                        {
                            number: "100M+",
                            label: "Event Triggers",
                            description: "Automated events handled monthly"
                        },
                        {
                            number: "500K+",
                            label: "Servers",
                            description: "Discord servers powered by our bots"
                        },
                        {
                            number: "2M+",
                            label: "Bots Created",
                            description: "Discord bots made with BotGhost"
                        },
                        {
                            number: "100K+",
                            label: "Active Developers",
                            description: "Discord developers who've claimed their Active Developer Badge"
                        }
                    ]}
                />

                {/* <CTABanner
                    title={<>Ready to build with our <span className="text-[#f45142]">Discord bot maker</span>?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    ctaText="Create your bot"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}

                {/* <RedCTA
                    title={<>Ready to build with our Discord bot maker?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    buttonText="Start creating for free"
                /> */}
                {/* <YouTubeSection
                    title="Watch how to make a Discord bot on BotGhost"
                    description="Learn how to make a Discord bot on BotGhost in under 5 minutes"

                // videoId="1aBMOGSdq3E"
                /> */}
                <TutorialsSection />
                <SecondaryFeatures
                    title={
                        <>
                            Create your own complex <span className="text-[#f45142]">Discord bot commands</span> without coding
                        </>
                    }
                    description="Design your own Discord bot commands with our easy to use command builder. Create custom commands, automate your server, and launch your bot in minutes - all without writing a single line of code."
                    features={secondaryFeatures}
                />
                {/* <CTABanner
                    title="Start creating your own Discord bot commands"
                    highlight="today"
                    subtitle="Get started with our intuitive Discord bot command builder"
                    ctaText="Start creating for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}



                <FAQ questions={faqQuestions} />

                <TestimonialCollage
                    title={<>What our users say about our <span className="text-[#f45142]">Discord bot command builder</span></>}
                    testimonials={testimonials}
                />

                <CTABanner
                    title={<>Start designing your own <span className="text-[#f45142]">Discord bot commands</span></>}
                    subtitle="Create your own Discord bot commands in minutes with our discord bot command builder"
                    ctaText="Start creating for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


                {/* <FAQ questions={faqQuestions} />

                <TutorialsSection />

                <SecondaryFeatures
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Build your perfect Discord bot with our drag-and-drop bot maker. Create custom commands, automate tasks, and manage your server with ease."
                    features={secondaryFeatures}
                />

                <YouTubeSection
                    title="Watch how to get the Active Developer Badge"
                    description="Learn how to get the Active Developer Badge in under 5 minutes"

                    videoId="1aBMOGSdq3E"
                />

                <CTABanner
                    title={<>Ready to get your <span className="text-[#f45142]">Active Developer Badge?</span></>}
                    subtitle="Join thousands of users who've built their perfect Discord bot without coding"
                    ctaText="Start creating for free"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/Discord_Active_Developer_Badge.svg.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />
 */}




                {/* <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div> */}

                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span>

                        </>
                    }
                    description="Discover our comprehensive suite of Discord bot hosting features designed to give you complete control. Create your own commands and events with our drag and drop builder."

                    features={mainFeatures}
                /> */}

                {/* <HomeCommandBuilder /> */}

                {/* <Testimonial
                    quote="BotGhost provides me with a convenient way to build custom bots for myself and various content creators I work for."
                    author="Discord Server Admin"
                    role="Admin for multiple Discord servers"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/SchnifL_pfp_r2.png"
                />


                <TestimonialCollage
                    testimonials={testimonials}
                />




                <ModulesSection />
                <RedCTA
                    title="Ready to power up your Discord server?"
                    subtitle="Join thousands of server owners who trust BotGhost for reliable, feature-rich Discord bot hosting."
                    buttonText="Start hosting for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Simple steps to start hosting your Discord bot on BotGhost"
                    description="Get started with professional Discord bot hosting in minutes"
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="/signup"
                /> */}



            </div>
        );
    }
}

export default DiscordBotCommandBuilder;
