import React, { Component } from 'react';

export class DetailedTutorials extends Component {
    render() {
        const {
            title = "How to get the Active Developer Badge",
            subtitle = "Follow these simple steps to get your Active Developer Badge in under 5 minutes",
            steps = [],
            ctaText = "Get Started Now",
            ctaLink = "https://dashboard.botghost.com/dashboard"
        } = this.props;

        return (
            <div className="bg-[#242830] py-32" id="tutorial">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
                            {title}
                        </h2>
                        <p className="text-xl text-[#9B9D9F] max-w-3xl mx-auto">
                            {subtitle}
                        </p>
                    </div>

                    <div className="space-y-24">
                        {steps.map((step, index) => (
                            <div
                                key={index}
                                className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} gap-16 items-center`}
                            >
                                <div className="md:w-1/2">
                                    <div className="bg-[#1E2124] p-8 rounded-2xl shadow-2xl">
                                        <div className="flex items-center gap-4 mb-4">
                                            <div className="bg-[#f45142] w-12 h-12 rounded-full flex items-center justify-center text-white font-bold text-2xl shadow-lg">
                                                {index + 1}
                                            </div>
                                            <h3 className="text-2xl font-bold text-white leading-tight">
                                                {step.title}
                                            </h3>
                                        </div>
                                        <p className="text-[#9B9D9F] text-lg leading-relaxed">
                                            {step.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="md:w-1/2">
                                    <img
                                        src={step.image}
                                        alt={step.title}
                                        className="rounded-2xl shadow-2xl w-full hover:scale-105 transition-transform duration-300"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </div>
        );
    }
}

export default DetailedTutorials; 