import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';
import DetailedTutorials from '../seo_elements/DetailedTutorials';
import StatsSection from '../seo_elements/StatsSection';
import HeroFeature from '../seo_elements/HeroFeature';
import FeaturePages from '../seo_elements/FeaturePages';
import Solutions from '../seo_elements/Solutions';

export class SEOHomePage extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const testimonials = [
            {
                title: "Host your own custom Discord bot",
                subtitle: "Create a custom Discord bot with no coding required",
                quote: "BotGhost makes your dream of owning a custom bot a reality with the easiest and most user-friendly experience!",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ninjapfp.webp",
                name: "NinjaChato",
                role: "Custom bot owner"
            },
            {
                quote: "BotGhost revolutionized our Discord with automation and interactive features, making our server efficient and engaging!",
                title: "Automate your Discord server",
                subtitle: 'Automate your Discord server with our custom built modules',
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/static-1-.png",
                name: "Silas",
                role: "Server Owner"
            },
            {
                quote: "BotGhost lets Rolace Legacy create quick, complex projects with no scripting, using powerful tools for seamless customization",
                title: "Create complex Discord bots",
                subtitle: "Host and create your own complex Discord bots",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/AstorRolace.png",
                name: "Astor Rolace",
                role: "Bot Creator"
            },
            {
                quote: "As a public bot owner BotGhost has given me the ability to create anything my brain can think of!",
                title: "Design your own public Discord bots",
                subtitle: "Become a professional bot designer",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Untitled294_20240719135012.png",
                name: "Oni",
                role: "Bot Designer"
            },
            {
                quote: "Easy to use, reliable, and looks great. I get so many compliments on my bots.",
                title: "Reliable Discord bot hosting",
                subtitle: "Host your own Discord bot with 99.9% uptime",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Fq2LXwKagAEpknu.jpg",
                name: "clowdy",
                role: "Discord Bot Owner"
            },
            {
                quote: "BotGhost is amazing! Its features and ease of use made me upgrade from monthly to a lifetime subscription without hesitation!",
                title: "Easy to use Discord Bot Maker",
                subtitle: "Create your own Discord bot with ease",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/discotools-xyz-icon.png",
                name: "Nathan",
                role: "Server Admin"
            }
            // ... more testimonials
        ];
        const mainFeatures = [
            {
                title: "Advanced Command Builder",
                description: "Create powerful Discord bot commands with our drag and drop builder. Design interactive menus, buttons, dropdowns, and modals using simple drag-and-drop blocks - no coding required.",
                bulletPoints: [
                    "Visual block-based editor",
                    "Real-time command preview"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SHP4.gif"
            },
            {
                title: "Professional Bot Hosting",
                description: "Host your Discord bot with enterprise-grade infrastructure. Our AWS-powered platform ensures 99.9% uptime, automatic scaling, and reliable performance for bots of any size.",
                bulletPoints: [
                    "24/7 Reliable hosting",
                    "Auto-scaling resources"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SHP1.png"
            },
            {
                title: "Event System Builder",
                description: "Automate your Discord server with our Discord event builder. Create custom welcome messages, role assignments, member tracking, and more without writing code.",
                bulletPoints: [
                    "Server event automation",
                    "Custom trigger conditions"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SHP2.png"
            },
            {
                title: "Advanced Message Builder",
                description: "Design stunning Discord embeds with our advanced message builder. Create rich messages with custom colors, fields, images, and buttons - all with real-time preview.",
                bulletPoints: [
                    "Visual embed builder",
                    "Interactive components"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SHP3.png"
            },
            {
                title: "Pre-built Module Library",
                description: "Choose from our extensive collection of ready-to-use modules. Add features like welcome messages, moderation tools, ticket systems, and more with just one click.",
                bulletPoints: [
                    "Instant functionality",
                    "Customizable settings"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Pre-built+Modules.gif"
            },
            {
                title: "Variable System",
                description: "Store and manage data with our easy to use condition system. Create counters, track statistics, store user preferences, and create dynamic responses.",
                bulletPoints: [
                    "Persistent data storage",
                    "Dynamic content"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Variable+System.png"
            },
            {
                title: "Conditional Logic",
                description: "Build intelligent bot behaviors with our visual logic system. Create complex workflows using if-then conditions, loops, and variables - no programming required.",
                bulletPoints: [
                    "Advanced decision trees",
                    "Dynamic responses"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ConditionalLogic.gif"
            },
            {
                title: "Marketplace",
                description: "Explore our community marketplace filled with pre-made commands and events. Install ready-to-use features or share your own creations with other bot creators.",
                bulletPoints: [
                    "1000+ commands and events",
                    "Community-driven content"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Marketplace.png"
            },
            {
                title: "Dashboard Analytics",
                description: "Monitor your bot's performance with BotPanel, our companion analytics service. Track detailed metrics, server growth, and user engagement across all your bots.",
                bulletPoints: [
                    "Cross-bot analytics",
                    "Advanced metrics with BotPanel"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SHP5.png"
            },
            {
                title: "Active Developer Badge",
                description: "Get the exclusive Discord Active Developer Badge in minutes. Enable our dedicated module, run a simple command, and claim your badge - no coding needed.",
                bulletPoints: [
                    "One-click setup",
                    "Automatic verification"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Active+Developer+Badge.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Visual Command Builder",
                description: "Create custom Discord bot commands with our intuitive drag-and-drop builder. Design complex interactions without writing any code.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/slash.webp"
            },
            {
                title: "Marketplace",
                description: "Browse our extensive marketplace filled with pre-made commands and events. Find and activate the perfect features for your server with just one click, or share your own creations with the community.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Marketplace.webp"
            },
            {
                title: "Detailed Documentation",
                description: "Get step-by-step instructions and examples for every feature. Learn how to use our Discord bot maker to its full potential.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Detailed-Documentation.webp"
            },
            {
                title: "Custom Variables",
                description: "Create dynamic bot responses with our powerful variable system. Store data, track stats, and personalize messages.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/vars.webp"
            },
            {
                title: "Module Library",
                description: "Enhance your bot with our extensive library of pre-built modules. Add features like welcome messages, auto-mod, and more with one click.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/modules.webp"
            },
            {
                title: "24/7 Hosting",
                description: "Your Discord bot stays online around the clock with our reliable hosting. Focus on creating while we handle the infrastructure.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/247-hosting.png"
            }
        ];



        const tutorialSteps = [
            {
                title: "Set Up Your Bot Application",
                description: <>
                    <p>Begin by navigating to the <a href="https://discord.com/developers/applications" target="_blank" className="text-[#f45142]">Discord Developer Portal</a>. Look for the 'New Application' button in the upper right corner.</p>
                    <p className="mt-2">Choose a name for your bot application (don't worry, you can change this later). Review and accept Discord's Terms and Developer Policy, then select 'Create'.</p>
                    <p className="mt-2">Once created, you'll land on the General Information section where you can add a description and tags for your bot.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CreateYourDiscordBot.png"
            },
            {
                title: "Create Your Bot User",
                description: <>
                    <p>Navigate to the 'Bot' section using the sidebar navigation on the left.</p>
                    <p className="mt-2">Select 'Add Bot' and confirm your choice when prompted with the verification popup.</p>
                    <p className="mt-2">Now you can personalize your bot with a custom username and avatar. Generate your bot token by clicking 'Reset Token' - <span className="font-bold text-[#f45142]">keep this token private and secure!</span></p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/GetYourBotToken.png"
            },
            {
                title: "Configure Bot Permissions",
                description: <>
                    <p>On the Bot settings page, locate the 'Privileged Gateway Intents' section below.</p>
                    <p className="mt-2">You'll need to activate these three essential intents:</p>
                    <ul className="list-disc list-inside mt-2 ml-4">
                        <li>PRESENCE INTENT</li>
                        <li>SERVER MEMBERS INTENT</li>
                        <li>MESSAGE CONTENT INTENT</li>
                    </ul>
                    <p className="mt-2">Don't forget to save your changes. <span className="text-[#f45142]">Note:</span> Leave 'Requires OAuth2 Code Grant' disabled.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/IntentsBG.gif"
            },
            {
                title: "Link with BotGhost",
                description: <>
                    <p>Visit your <a href="https://dashboard.botghost.com/dashboard" target="_blank" className="text-[#f45142]">BotGhost Dashboard</a> (sign in through Discord if you haven't already).</p>
                    <p className="mt-2">Find the 'Bot Token' field in the New Bot setup and paste your token there.</p>
                    <p className="mt-2">Hit 'Create Bot' and we'll take care of connecting everything behind the scenes.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BotMaker4.png"
            },
            {
                title: "Add to Your Server",
                description: <>
                    <p>Look for the 'Invite' option in your BotGhost dashboard's sidebar.</p>
                    <p className="mt-2">Choose which Discord server you want your bot to join from the available options.</p>
                    <p className="mt-2">We've already selected the optimal permissions your bot needs - no need to change these.</p>
                    <p className="mt-2">Follow the prompts to authorize and add your bot to the selected server.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BotMaker5.png"
            },
            {
                title: "Begin Building",
                description: <>
                    <p>Congratulations! Your bot is live and ready. Here's what you can do next:</p>
                    <ul className="list-disc list-inside mt-2 ml-4">
                        <li>Browse and activate modules from our extensive library</li>
                        <li>Build custom commands with our visual editor</li>
                        <li>Set up automated events and welcome messages</li>
                        <li>Track your bot's usage and performance</li>
                    </ul>
                    <p className="mt-2">Try using / in your server to explore your bot's commands!</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BotMaker6.png"
            }
        ];

        const faqQuestions = [
            {
                question: "What is BotGhost's Discord Bot Maker?",
                answer: "BotGhost's Discord Bot Maker is a visual bot creation platform that lets you build custom Discord bots without any coding. Using our drag-and-drop interface, you can create commands, automate tasks, and manage your server - all through an intuitive dashboard."
            },
            {
                question: "Do I need coding experience to make a Discord bot?",
                answer: "No! BotGhost is designed to be completely code-free. Our visual builder lets you create professional Discord bots by simply dragging and dropping components. Whether you're making simple commands or complex automations, no coding knowledge is required."
            },
            {
                question: "Is BotGhost free to use?",
                answer: "Yes! You can create and host your Discord bot for free with BotGhost. Our free plan includes essential features like custom commands, basic automations, and 24/7 hosting. Premium plans are available for advanced features and higher usage limits."
            },
            {
                question: "What features can I add to my Discord bot?",
                answer: "With BotGhost, you can add a wide range of features including welcome messages, custom commands, role management, moderation tools, ticket systems, reaction roles, giveaways, and much more. You can also create your own custom features using our visual command and event builders."
            },
            {
                question: "How reliable is BotGhost's bot hosting?",
                answer: "BotGhost provides enterprise-grade hosting with 99.9% uptime. Your bot stays online 24/7, and we automatically scale resources based on your needs. Our infrastructure is built on AWS, ensuring reliable performance for bots of any size."
            },
            {
                question: "How quickly can I create a Discord bot?",
                answer: "You can have your bot up and running in minutes! Simply create your Discord application, connect it to BotGhost, and start adding features. Our pre-built modules let you add functionality instantly, while the visual builder makes creating custom features quick and easy."
            },
            {
                question: "Can I customize my bot's appearance and behavior?",
                answer: "Absolutely! You can customize everything about your bot including its name, profile picture, command responses, messages, and behaviors. Our visual builder gives you complete control over how your bot looks and functions in your server."
            }
        ];

        return (
            <div>
                <Helmet>
                    <title>BotGhost | Create a Free Discord Bot</title>
                    <meta name="description" content="Make your own Discord bot for free with no coding required. " />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            Create your own <span className="text-[#f45142]">Discord bot</span> - no coding required
                        </>
                    }
                    description="Create your own Discord bot with our drag and drop discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - all without writing a single line of code."
                    cta="Create a Discord bot"
                    ctaSecondary="See how it works"
                    ctaSecondaryLink="#demo"
                    stats={true}
                />

                <SEOBuilderDemo />

                <FeatureSection
                    id="features"
                    title={
                        <>
                            Create your own <span className="text-[#f45142]">Discord bot</span> without any coding
                            {/* Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span> */}
                            {/* Track <span className="text-[#f45142]">seamless</span> bot hosting
                            <br /> with our platform */}
                        </>
                    }
                    description="Create your own complex Discord bot commands, automate your server with custom events or use our pre-built modules to get started."

                    features={mainFeatures}
                />

                <Testimonial
                    quote="Simple & Customizable; A dream to bot makers who have coding knowledge but are short on time."
                    author="Fluster"
                    role="Discord Bot Maker"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/e443cd1a18680d56fd27f2d286c389e7.png"
                />


                <HeroFeature
                    title={<>Create commands with your own complex commands and automations</>}
                    description="Build powerful Discord bot commands and automations using our easy to use command builder. Drag, drop and connect blocks to create your very own Discord bot."
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
                    bulletPoints={[
                        "Drag-and-drop command builder",
                        "Interactive components & buttons",
                        "Detailed Error Handling",
                        "Complete Documentation",
                    ]}
                    ctaText="Start building your bot"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />
                <TestimonialCollage
                    testimonials={testimonials}
                />

                <FeaturePages />
                <DetailedTutorials
                    title={<>How to make a <span className="text-[#f45142]">Discord bot</span> on BotGhost</>}
                    subtitle="Follow these simple steps to create your own Discord bot using our discord bot maker."
                    steps={tutorialSteps}
                    ctaText="Create your bot"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />






                <StatsSection
                    title={<>The most trusted <span className="text-[#f45142]">Discord bot maker</span> platform</>}
                    description="Join millions of users who've created their perfect Discord bot with our easy to use Discord bot maker"
                    stats={[
                        {
                            number: "1.5M+",
                            label: "Users",
                            description: "Active users trust our Discord bot maker"
                        },
                        {
                            number: "10M+",
                            label: "Commands Created",
                            description: "Custom commands built by our community"
                        },
                        {
                            number: "100M+",
                            label: "Event Triggers",
                            description: "Automated events handled monthly"
                        },
                        {
                            number: "500K+",
                            label: "Servers",
                            description: "Discord servers powered by our bots"
                        },
                        {
                            number: "2M+",
                            label: "Bots Created",
                            description: "Discord bots made with BotGhost"
                        },
                        {
                            number: "100K+",
                            label: "Active Developers",
                            description: "Discord developers who've claimed their Active Developer Badge"
                        }
                    ]}
                />

                {/* <CTABanner
                    title={<>Ready to build with our <span className="text-[#f45142]">Discord bot maker</span>?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    ctaText="Create your bot"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}
                <ModulesSection />
                <FAQ questions={faqQuestions} />

                {/* <YouTubeSection
                    title="Watch how to make a Discord bot on BotGhost"
                    description="Learn how to make a Discord bot on BotGhost in under 5 minutes"

                // videoId="1aBMOGSdq3E"
                /> */}
                <Solutions />
                {/* <TutorialsSection /> */}
                <SecondaryFeatures
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Build your perfect Discord bot with our drag-and-drop bot maker. Create custom commands, automate tasks, and manage your server with ease."
                    features={secondaryFeatures}
                />

                <CTABanner
                    title="Start building your Discord bot today"
                    highlight="today"
                    subtitle="Get started with our intuitive Discord bot maker"
                    ctaText="Start creating for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />




                {/* <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div> */}

                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span>

                        </>
                    }
                    description="Discover our comprehensive suite of Discord bot hosting features designed to give you complete control. Create your own commands and events with our drag and drop builder."

                    features={mainFeatures}
                /> */}

                {/* <HomeCommandBuilder /> */}

                {/* <Testimonial
                    quote="BotGhost provides me with a convenient way to build custom bots for myself and various content creators I work for."
                    author="Discord Server Admin"
                    role="Admin for multiple Discord servers"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/SchnifL_pfp_r2.png"
                />


                <TestimonialCollage
                    testimonials={testimonials}
                />




                <ModulesSection />
                <RedCTA
                    title="Ready to power up your Discord server?"
                    subtitle="Join thousands of server owners who trust BotGhost for reliable, feature-rich Discord bot hosting."
                    buttonText="Start hosting for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Simple steps to start hosting your Discord bot on BotGhost"
                    description="Get started with professional Discord bot hosting in minutes"
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="/signup"
                /> */}

            </div>
        );
    }
}

export default SEOHomePage;
