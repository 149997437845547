import React from 'react';
import { Link } from 'react-router-dom';

const FeaturePages = () => {
    const features = [
        {
            title: "Discord Bot Hosting",
            description: "Professional Discord bot hosting with 99.9% uptime. Host your bot with enterprise-grade infrastructure.",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/247-hosting.png",
            link: "/discord-bot-hosting"
        },
        {
            title: "Active Developer Badge",
            description: "Get your Discord Active Developer Badge in under 5 minutes. No coding required.",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ActiveDev.png.webp",
            link: "/active-developer-badge"
        },
        {
            title: "Discord Bot Maker",
            description: "Create your own Discord bot with our easy-to-use bot maker. Design commands and automate your server.",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ServerEvent.png",
            link: "/discord-bot-maker"
        },
        {
            title: "Command Builder",
            description: "Create custom Discord bot commands with our visual builder. Design interactive responses and automations.",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/slash.webp",
            link: "/discord-bot-command-builder"
        },
        {
            title: "Event Builder",
            description: "Build automated Discord bot events with our visual builder. Create custom triggers and responses.",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CustomEvent.png",
            link: "/discord-bot-event-builder"
        },
        {
            title: "Custom Discord Bot",
            description: "Build a custom Discord bot tailored to your needs. Create unique features and automations.",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/modules.webp",
            link: "/custom-discord-bot"
        }
    ];

    return (
        <div className="bg-[#1E2124] py-32">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                        Create the Discord bot of your <span className="text-[#f45142]">dreams</span>
                    </h2>
                    <p className="text-xl text-[#9B9D9F] max-w-3xl mx-auto">
                        Discover what makes BotGhost the perfect way to create your own Discord bot
                    </p>
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
                    {features.map((feature, index) => (
                        <Link
                            key={index}
                            to={feature.link}
                            className="relative bg-gradient-to-br from-[#242830] to-[#1E2124] rounded-2xl p-8 hover:translate-y-[-4px] transition-all duration-300 group hover:shadow-lg border border-[#2A2F36]/50 hover:border-[#f45142]/50 overflow-hidden"
                        >
                            <div className="flex items-center gap-4 mb-6">
                                <img
                                    src={feature.icon}
                                    alt={feature.title}
                                    className="w-16 h-16 group-hover:scale-105 transition-transform duration-300"
                                />
                            </div>
                            <h3 className="text-xl font-bold text-white mb-3 group-hover:text-[#f45142] transition-colors duration-200">
                                {feature.title}
                            </h3>
                            <p className="text-[#9B9D9F] text-sm">
                                {feature.description}
                            </p>
                            <div className="mt-6 text-[#f45142] font-semibold text-sm flex items-center justify-end">
                                Learn more
                                <svg
                                    className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform duration-200"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FeaturePages; 