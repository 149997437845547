import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';
import DetailedTutorials from '../seo_elements/DetailedTutorials';
import StatsSection from '../seo_elements/StatsSection';
import HeroFeature from '../seo_elements/HeroFeature';
import BuilderOptions from '../seo_elements/BuilderOptions';
import EventOptions from '../seo_elements/EventOptions';
import DocumentationLinks from '../seo_elements/DocumentationLinks';

export class DiscordBotEventBuilder extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const testimonials = [
            {
                title: "Simple Command Builder",
                subtitle: "Create a custom Discord bot with no coding required",
                quote: "Nothing comes close to BotGhost's simplicity and functionality.",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/3e1e41d58ed87d324887c17e3a9affea.jpg",
                name: "Proquer",
                role: "Bot Owner"
            },
            {
                quote: "BotGhost is very easy to use and very high quality services!",
                title: "Easy to use",
                subtitle: 'Easily create your own Discord bot commands',
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/pepe.jpg",
                name: "Dixon",
                role: "Server Admin"
            },
            {
                quote: "BotGhost's modules such as the Command Builder are very simple for use and easy to learn.",
                title: "Easy to use",
                subtitle: "Intuitive drag and drop interface",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/IMG_7277.png",
                name: "Mohammed",
                role: "Bot Designer"
            },
            {
                quote: "BotGhost is an amazing way to make your discord bots for free without any knowledge of coding.",
                title: "Discord Commands without coding",
                subtitle: "Create your own Discord bot commands",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/0.png",
                name: "Real_Trex24",
                role: "Discord Bot Maker"
            },
            // ... more testimonials
        ];
        const mainFeatures = [
            {
                title: "Drag and Drop Bot Maker",
                description: "Create your Discord bot with our intuitive drag-and-drop interface. Design custom commands, events, and automations without writing a single line of code. Perfect for beginners and experts alike.",
                bulletPoints: [
                    "Visual command builder",
                    "No coding knowledge needed"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
            },
            {
                title: "Advanced Command Builder",
                description: "Build complex commands with our powerful visual builder. Create interactive menus, buttons, modals, and more using our drag-and-drop interface. Perfect for creating engaging bot interactions.",
                bulletPoints: [
                    "Interactive components",
                    "If/Else Statements",
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CommandBuilderBG.png"
            },
            {
                title: "Pre-built Modules",
                description: "Choose from our extensive library of pre-built modules to add instant functionality to your bot. From welcome messages to moderation tools, we've got everything you need to enhance your server.",
                bulletPoints: [
                    "One-click module activation",
                    "Fully customizable"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Variable System",
                description: "Create dynamic responses with our powerful variable system. Store and retrieve data, create counters, track user stats, and more - all without touching any code.",
                bulletPoints: [
                    "Dynamic data storage",
                    "Custom variables"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/VariablesBG.png"
            },
            {
                title: "Advanced Message Builder",
                description: "Create beautiful Discord embeds with our visual embed designer. Customize colors, add fields, images, and more. Preview your embeds in real-time as you build them.",
                bulletPoints: [
                    "Visual embed builder",
                    "Real-time preview"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/EmbedBG.png"
            },
            {
                title: "Easy Bot Management",
                description: "Manage your Discord bot from our user-friendly dashboard. Monitor performance, update settings, and make changes to your bot in real-time - all from one central location.",
                bulletPoints: [
                    "Intuitive dashboard",
                    "Real-time configuration"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Conditional Logic",
                description: "Add smart decision-making to your bot with our visual logic builder. Create if-then conditions, loops, and complex workflows without any programming knowledge.",
                bulletPoints: [
                    "Visual logic editor",
                    "Complex automations"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/LogicBG.png"
            },
            {
                title: "Event System Builder",
                description: "Create automated responses to server events using our visual event builder. Set up welcome messages, member tracking, role assignments, and more without any coding.",
                bulletPoints: [
                    "Drag and drop event builder",
                    "Automated responses"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ScalableInfastructureBG.png"
            },
            {
                title: "Marketplace",
                description: "Browse our extensive marketplace filled with pre-made commands and events. Find and activate the perfect features for your server with just one click, or share your own creations with the community.",
                bulletPoints: [
                    "1000+ commands and events",
                    "One click activation"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/DatabaseBG.png"
            },
            {
                title: "Active Developer Badge",
                description: "Get the exclusive Discord Active Developer Badge in under 5 minutes. No coding required - just enable our module and run a command.",
                bulletPoints: [
                    "One-click setup",
                    "24hour wait time"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active_dev.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Visual Event Builder",
                description: "Create powerful Discord event automations with our intuitive drag-and-drop interface. Design complex event flows by connecting blocks - no coding required.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
            },
            {
                title: "Message Events",
                description: "Respond to message events in your server. Create automated responses, moderate content, and track message activity across all channels.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/MessageEvent.png"
            },
            {
                title: "Member Events",
                description: "Track member activity in your server. Automatically welcome new members, assign roles, and monitor member status changes.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/MemberEvent.png"
            },
            {
                title: "Server Events",
                description: "Monitor and respond to server-wide events. Track boosts, emoji updates, role changes, and more with automated actions.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ServerEvent.png"
            },
            {
                title: "Voice Events",
                description: "Automate voice channel management. Track when members join or leave voice channels, create temporary channels, and manage voice permissions.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/VoiceEvent.png"
            },
            {
                title: "Event Templates",
                description: "Get started quickly with our pre-built event templates. Choose from welcome messages, role management, moderation, and more automation templates.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/EventTemplate.png"
            }
        ];

        const faqQuestions = [
            {
                question: "How to make Discord events?",
                answer: "Creating Discord events with BotGhost is simple: 1) Go to your bot's dashboard 2) Click 'Create Event' 3) Choose your event type (message, member, server, etc.) 4) Use our drag-and-drop builder to design your automation flow 5) Save and your event automation is live! No coding required."
            },
            {
                question: "How to create events on Discord?",
                answer: "To create event automations on Discord: 1) Select an event trigger (like when a member joins) 2) Choose what should happen (send welcome message, assign role, etc.) 3) Add conditions if needed (member account age, specific roles, etc.) 4) Test your automation 5) Enable it for your server. Your bot will automatically respond to events as configured."
            },
            {
                question: "How to make events in Discord?",
                answer: "Making Discord event automations is easy with our visual builder: 1) Pick from 50+ event triggers 2) Design your response using drag-and-drop blocks 3) Add conditions and variables to make it dynamic 4) Set up error handling 5) Test in real-time 6) Enable for your server. Your automation will start working immediately!"
            },
            {
                question: "What are Discord events?",
                answer: "Discord events are actions that happen in your server that your bot can respond to automatically. These include: 1) Message events (sent, edited, deleted) 2) Member events (joining, leaving, roles) 3) Server events (boosts, emoji updates) 4) Voice events (members joining/leaving channels) and more. You can create automated responses to any of these events."
            },
            {
                question: "How to create events in Discord server?",
                answer: "To create server automations: 1) Choose which server event to respond to (member joins, messages, etc.) 2) Design your automation flow with our visual builder 3) Add custom messages, role assignments, or other actions 4) Set any required conditions 5) Test and enable. Your bot will then automatically respond when these events occur."
            },
            {
                question: "How to test Discord event automations?",
                answer: "Testing event automations is built into our platform: 1) Use the real-time preview in the builder 2) Try different event scenarios 3) Check your conditions and responses 4) Monitor the event logs to see what's happening 5) Make adjustments as needed. You can also use a test server to verify everything works as expected."
            },
            {
                question: "How to manage Discord event automations?",
                answer: "Managing your event automations is simple: 1) View all your automations in the dashboard 2) Enable/disable them with one click 3) Edit existing automations anytime 4) Check the logs to see when they trigger 5) Set up notifications for important events. You have full control over how your bot responds to server events."
            }
        ];

        return (
            <div>
                <Helmet>
                    <title>Discord Bot Event Builder | BotGhost</title>
                    <meta name="description" content="Create your own Discord bot without coding using our easy-to-use Discord bot event builder. Build custom commands, automate your server, and launch your bot in minutes." />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            Easy to use <span className="text-[#f45142]">Discord Bot Event Builder</span>
                        </>
                    }
                    description="Build automations for your discord server with our easy to use event builder. Create custom events, automate your server, and launch your bot in minutes - all without writing a single line of code."
                    cta="Automate your server"
                    ctaSecondary="Event Builder"
                    ctaSecondaryLink="#demo"
                    stats={true}
                />

                <SEOBuilderDemo
                    event={true}
                    title={<>Try out our builder to <span className="text-[#f45142]">create your own event</span></>}
                    description="Drag and drop option, action and condition blocks to create your own event."
                />

                <EventOptions
                    title="Create powerful Discord event automations"
                    description="Choose from our extensive list of Discord events to automate your server. Set up welcome messages, role assignments, member tracking and more - all without coding."
                />



                {/* <DetailedTutorials
                    title={<>How to make a <span className="text-[#f45142]">Discord bot</span> on BotGhost</>}
                    subtitle="Follow these simple steps to create your own Discord bot using our discord bot maker."
                    steps={tutorialSteps}
                    ctaText="Create your bot"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}


                <HeroFeature
                    title={<>Automate your Discord Server!</>}
                    description="Create complex Discord bot events with our discord bot event builder. Choose from a wide range of actions and conditions to automate your server."
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
                    bulletPoints={[
                        "Drag-and-drop event builder",
                        "Add buttons and menus",
                        "Respond to events",
                        "No coding required"
                    ]}
                    ctaText="Start automating your server"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Create your own Discord bot with our intuitive discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - all without writing a single line of code."

                    features={mainFeatures}
                /> */}

                {/* <Testimonial
                    quote="Simple & Customizable; A dream to bot makers who have coding knowledge but are short on time."
                    author="Fluster"
                    role="Discord Bot Maker"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/e443cd1a18680d56fd27f2d286c389e7.png"
                /> */}


                <StatsSection
                    title={<>The most used <span className="text-[#f45142]">Discord bot event builder</span> </>}
                    description="Join millions of users who've created their perfect Discord bot with our powerful bot maker"
                    stats={[
                        {
                            number: "1.5M+",
                            label: "Users",
                            description: "Active users trust our Discord bot maker"
                        },
                        {
                            number: "10M+",
                            label: "Commands Created",
                            description: "Custom commands built by our community"
                        },
                        {
                            number: "100M+",
                            label: "Event Triggers",
                            description: "Automated events handled monthly"
                        },
                        {
                            number: "500K+",
                            label: "Servers",
                            description: "Discord servers powered by our bots"
                        },
                        {
                            number: "2M+",
                            label: "Bots Created",
                            description: "Discord bots made with BotGhost"
                        },
                        {
                            number: "100K+",
                            label: "Active Developers",
                            description: "Discord developers who've claimed their Active Developer Badge"
                        }
                    ]}
                />

                <RedCTA
                    title={<>Ready to start automating your Discord server?</>}
                    subtitle="Automate your server with our easy to use event builder"
                    buttonText="Start creating for free"
                />

                {/* <CTABanner
                    title={<>Ready to build with our <span className="text-[#f45142]">Discord bot maker</span>?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    ctaText="Create your bot"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}

                {/* <RedCTA
                    title={<>Ready to build with our Discord bot maker?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    buttonText="Start creating for free"
                /> */}
                {/* <YouTubeSection
                    title="Watch how to make a Discord bot on BotGhost"
                    description="Learn how to make a Discord bot on BotGhost in under 5 minutes"

                // videoId="1aBMOGSdq3E"
                /> */}
                <TutorialsSection />
                <SecondaryFeatures
                    title={
                        <>
                            Respond to Discord events with <span className="text-[#f45142]">complex automations</span>
                        </>
                    }
                    description="Create complex Discord bot events with our discord bot event builder. Choose from a wide range of actions and conditions to automate your server."
                    features={secondaryFeatures}
                />
                {/* <CTABanner
                    title="Start creating your own Discord bot commands"
                    highlight="today"
                    subtitle="Get started with our intuitive Discord bot command builder"
                    ctaText="Start creating for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}



                <FAQ questions={faqQuestions} />



                {/* <TestimonialCollage
                    testimonials={testimonials}
                /> */}

                <CTABanner
                    title={<>Start designing your own <span className="text-[#f45142]">Discord bot commands</span></>}
                    subtitle="Create your own Discord bot commands in minutes with our discord bot command builder"
                    ctaText="Start creating for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


                {/* <FAQ questions={faqQuestions} />

                <TutorialsSection />

                <SecondaryFeatures
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Build your perfect Discord bot with our drag-and-drop bot maker. Create custom commands, automate tasks, and manage your server with ease."
                    features={secondaryFeatures}
                />

                <YouTubeSection
                    title="Watch how to get the Active Developer Badge"
                    description="Learn how to get the Active Developer Badge in under 5 minutes"

                    videoId="1aBMOGSdq3E"
                />

                <CTABanner
                    title={<>Ready to get your <span className="text-[#f45142]">Active Developer Badge?</span></>}
                    subtitle="Join thousands of users who've built their perfect Discord bot without coding"
                    ctaText="Start creating for free"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/Discord_Active_Developer_Badge.svg.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />
 */}




                {/* <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div> */}

                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span>

                        </>
                    }
                    description="Discover our comprehensive suite of Discord bot hosting features designed to give you complete control. Create your own commands and events with our drag and drop builder."

                    features={mainFeatures}
                /> */}

                {/* <HomeCommandBuilder /> */}

                {/* <Testimonial
                    quote="BotGhost provides me with a convenient way to build custom bots for myself and various content creators I work for."
                    author="Discord Server Admin"
                    role="Admin for multiple Discord servers"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/SchnifL_pfp_r2.png"
                />


                <TestimonialCollage
                    testimonials={testimonials}
                />




                <ModulesSection />
                <RedCTA
                    title="Ready to power up your Discord server?"
                    subtitle="Join thousands of server owners who trust BotGhost for reliable, feature-rich Discord bot hosting."
                    buttonText="Start hosting for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Simple steps to start hosting your Discord bot on BotGhost"
                    description="Get started with professional Discord bot hosting in minutes"
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="/signup"
                /> */}

                <DocumentationLinks
                    title="Master Discord Event Automation"
                    description="Explore our comprehensive guides and documentation to learn everything about Discord event automation"
                    links={[
                        {
                            title: "Getting Started",
                            description: "Learn how to create your first bot on BotGhost",
                            link: "https://docs.botghost.com/getting-started/create-your-first-bot",
                            category: "Getting Started"
                        },
                        {
                            title: "Event Builder Guide",
                            description: "Learn how to use our visual event builder to create powerful automations",
                            link: "https://docs.botghost.com/custom-commands-and-events/command-and-event-builder",
                            category: "Getting Started"
                        },
                        {
                            link: "https://docs.botghost.com/getting-started/standard-practices",
                            title: "Standard Practices",
                            description: "Frequently asked questions about BotGhost. Some best practices to follow when creating your own Discord bot.",
                            category: "Getting Started"
                        },
                        {
                            title: "Event Actions",
                            description: "Learn how to use our visual event builder to create powerful automations",
                            link: "https://docs.botghost.com/custom-commands-and-events/actions",
                            category: "Event Builder"
                        },
                        {
                            link: "https://docs.botghost.com/custom-commands-and-events/events",
                            title: "Special Events",
                            description: "Connect your bot to outside services and APIs",
                            category: "Event Builder"
                        },
                        {
                            link: "https://docs.botghost.com/custom-commands-and-events/data-storage",
                            title: "Data Storage",
                            description: "Create your own custom data storage for your bot",
                            category: "Event Builder"
                        },
                        // Add more links as needed
                    ]}
                />

            </div>
        );
    }
}

export default DiscordBotEventBuilder;
