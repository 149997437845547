import React from 'react';
import { Link } from 'react-router-dom';

const Solutions = () => {
    const solutions = [
        {
            title: "For Discord Communities",
            subtitle: "Perfect for growing servers",
            description: "Build engagement and automate your community management with custom bots.",
            features: [
                "Custom welcome messages and onboarding",
                "Automated role management",
                "Community engagement tools",
                "Server analytics and insights"
            ],
            cta: "Start growing",
            ctaLink: "https://dashboard.botghost.com/dashboard",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/modules.webp"
        },
        {
            title: "For Bot Developers",
            subtitle: "Build your dream bot",
            description: "Create a professional Discord bot used by millions. Turn your ideas into reality.",
            features: [
                "Professional bot development tools",
                "Advanced customization options",
                "Scale to millions of users",
                "Join the developer community"
            ],
            cta: "Start building",
            ctaLink: "https://dashboard.botghost.com/dashboard",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/modules.webp"
        },
        {
            title: "For Content Creators",
            subtitle: "Engage your community",
            description: "Create unique experiences for your Discord community with custom bots and automation.",
            features: [
                "Exclusive member perks",
                "Community event automation",
                "Custom interactive commands",
                "Subscriber engagement tools"
            ],
            cta: "Start creating",
            ctaLink: "https://dashboard.botghost.com/dashboard",
            icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/modules.webp"
        }
    ];

    return (
        <div className="bg-[#1E2124] py-32">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-24">
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                        {/* Ready to see what you can <span className="text-[#f45142]">achieve</span>? */}
                        Create the Discord bot of your <span className="text-[#f45142]">dreams</span>
                    </h2>
                    <p className="text-xl text-[#9B9D9F] max-w-3xl mx-auto">
                        Whether you're growing a community, starting a business, or building enterprise solutions,
                        BotGhost helps you reach your goals
                    </p>
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {solutions.map((solution, index) => (
                        <div
                            key={index}
                            className="bg-[#242830] rounded-2xl p-8 hover:scale-105 transition-transform duration-300"
                        >
                            <div className="flex items-center gap-4 mb-6">
                                <img
                                    src={solution.icon}
                                    alt={solution.title}
                                    className="w-12 h-12"
                                />
                                <div>
                                    <h3 className="text-white font-bold text-xl">
                                        {solution.title}
                                    </h3>
                                    <p className="text-[#f45142] text-sm">
                                        {solution.subtitle}
                                    </p>
                                </div>
                            </div>

                            <p className="text-[#9B9D9F] mb-6">
                                {solution.description}
                            </p>

                            <ul className="space-y-3 mb-8">
                                {solution.features.map((feature, featureIndex) => (
                                    <li
                                        key={featureIndex}
                                        className="flex items-center text-sm text-white"
                                    >
                                        <svg className="w-5 h-5 mr-3 text-[#f45142]" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                        {feature}
                                    </li>
                                ))}
                            </ul>

                            <Link
                                to={solution.ctaLink}
                                className="inline-block w-full text-center bg-[#f45142] text-white px-6 py-3 rounded-lg font-semibold hover:bg-[#ff6152] transition-colors duration-200"
                            >
                                {solution.cta}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Solutions; 